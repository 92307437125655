<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗大板镇商品信息</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="title"><span>售卖牲畜数量</span></div>
          <!-- <bing-tu :value="data1" /> -->
          <ban-bing-tu :value="data1" />
        </div>

        <div class="left_box">
          <div class="title"><span>售卖机械数量</span></div>
          <!-- <bing-tu :value="data2" /> -->
          <kong-xin-bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <da-ban-map />

          <!-- <div class="map">
            <img src="../../../../assets/images/ba-lin-you-qi/map.png" alt="">
          </div> -->
        </div>

        <div class="context_box">
         
        </div>

        <div class="bottom_box">
          <div class="title"><span>售卖草料数量</span></div>
          <zhu-zhuang-tu class="chart" :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="cattle_list">
          <div class="title font_number"><span>牲畜列表{{ ' ' + productList.length }}</span></div>

          <div class="list_box p_20">
            <div 
              class="list_item" 
              v-for="(item, index) in productList" :key="index"
              @click="showOxDetail(index)"
            >
              <div class="img_box w_30_p h_160">
                <div class="img full">
                  <!-- <img class="full border_radius_10" :src="item.img_url" alt=""> -->
                  <img class="full border_radius_10" v-if="index == 0" src="../../../../assets/images/ba-lin-you-qi/110.jpg" alt="">
                  <img class="full border_radius_10" v-if="index == 1" src="../../../../assets/images/ba-lin-you-qi/111.png" alt="">
                  <img class="full border_radius_10" v-if="index == 2" src="../../../../assets/images/ba-lin-you-qi/112.png" alt="">
                  <img class="full border_radius_10" v-if="index == 3" src="../../../../assets/images/ba-lin-you-qi/113.png" alt="">
                </div>
              </div>

              <div class="info_box w_70_p flex_column_around pl_20">
                <div class="h_30 lh_30 fs_20">品种: {{ item.breed_type}} </div>
                <div class="h_30 lh_30 fs_20">状态: {{ item.current_state}} </div>
                <div class="h_30 lh_30 fs_20">毛色: {{ item.fur_color}} </div>
                <div class="h_30 lh_30 fs_20">性别: {{ item.sex}} </div>
                <div class="h_30 lh_30 fs_20">售价: <span class="font_number main_color">{{ item.price + ' '}}</span>元 </div>
              </div>
            </div>
          </div> 
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import { getMuHuDetail } from '../../../../config/api';
  import DaBanMap from '../maps/daBanMap.vue';
  import BingTu from '../charts/bing-tu.vue';
  import BanBingTu from '../charts/ban-bing-tu.vue';
  import KongXinBingTu from '../charts/kong-xin-bing-tu.vue';
  import ZhuZhuangTu from '../charts/zhu-zhuang-tu.vue';

  
  export default {
    name: 'showPage1',
    components: { BaLinYouQiMap, DaBanMap, BingTu, BanBingTu, KongXinBingTu, ZhuZhuangTu, },
    data() {
      return {
        activeIndex: 0,
        makidoName: '',
        data1: {},
        data2: {},
        muHuDetailData: {},

        img1: '../../../../assets/images/ba-lin-you-qi/110.png',
        img2: '../../../../assets/images/ba-lin-you-qi/111.png',
        img3: '../../../../assets/images/ba-lin-you-qi/112.png',
        img4: '../../../../assets/images/ba-lin-you-qi/113.png',

        list: [],
        productList: [
          {
            birth_date: "2018-03-23",
            breed_type: "西门塔尔",
            code: "XM20180323641BA1EBD260E",
            create_time: "2023-04-01T22:57:55.920112",
            current_state: "成牛",
            detail_page: "https://cattlecf.nmqner.com/cattle.php?t=herds&op=info&areaid=150423000000&id=1166459",
            ear_num: "1016",
            fur_color: "红白花",
            generation: "第 1 牛",
            has_horn: true,
            id: 733,
            img_url: '../../../../assets/images/ba-lin-you-qi/112.png',
            registered_time: "2023-03-23T08:48:43",
            sex: "母牛",
            update_time: "2023-04-06T20:41:22.158582",
            ymid: 1166459,
            yzh: 11,
            price: 12400
          },
          {
            birth_date: "2020-03-23",
            breed_type: "西门塔尔",
            code: "XM20200323641BA19264CE8",
            create_time: "2023-04-01T22:57:55.926474",
            current_state: "成牛",
            detail_page: "https://cattlecf.nmqner.com/cattle.php?t=herds&op=info&areaid=150423000000&id=1166452",
            ear_num: "1015",
            fur_color: "黄白花",
            generation: "第 1 牛",
            has_horn: true,
            id: 734,
            img_url: '../../../../assets/images/ba-lin-you-qi/113.png',
            old_ear_num: "",
            registered_time: "2023-03-23T08:47:14",
            sex: "母牛",
            update_time: "2023-04-06T20:41:22.163165",
            ymid: 1166452,
            yzh: 11,
            price: 15000
          },
          {
            birth_date: "2019-03-23",
            breed_type: "西门塔尔",
            code: "XM20190323641BA13BCF358",
            create_time: "2023-04-01T22:57:55.931137",
            current_state: "成牛",
            detail_page: "https://cattlecf.nmqner.com/cattle.php?t=herds&op=info&areaid=150423000000&id=1166449",
            ear_num: "1014",
            fur_color: "红白花",
            generation: "第 1 牛",
            has_horn: false,
            id: 735,
            img_url: '../../../../assets/images/ba-lin-you-qi/111.png',
            old_ear_num: "",
            registered_time: "2023-03-23T08:45:47",
            sex: "母牛",
            update_time: "2023-04-06T20:41:22.167776",
            ymid: 1166449,
            yzh: 11,
            price: 17000
          },
          {
            birth_date: "2019-03-23",
            breed_type: "西门塔尔",
            code: "XM20190323641BA0BE2BE91",
            create_time: "2023-04-01T22:57:55.936238",
            current_state: "成牛",
            ear_num: "1013",
            fur_color: "黄白花",
            generation: "第 1 牛",
            has_horn: true,
            id: 736,
            img_url: '../../../../assets/images/ba-lin-you-qi/110.png',
            old_ear_num: "",
            registered_time: "2023-03-23T08:43:42",
            sex: "母牛",
            update_time: "2023-04-06T20:41:22.172479",
            ymid: 1166438,
            yzh: 11,
            price: 10800
          }
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)

      // let muHuId = this.$route.params.val

      // 通过牧户 id 获取 牧户详情信息
      // this.getMuHuDetail(muHuId)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '牲畜数量',
          unit: '头',
          data: [
            { name: '成牛', value: 12, unit: '' },
            { name: '犊牛', value: 32, unit: '' },
            { name: '育肥牛', value: 43, unit: '' },
            { name: '检疫牛', value: 54, unit: '' },
            { name: '其它牲畜', value: 35, unit: '' }
          ]
        }

        this.data2 = {
          title: '机械数量',
          unit: '辆',
          data: [
            {name: "小型拖拉机", value: 20, unit: ' '},
            {name: "大型拖拉机", value: 17, unit: ' '},
            {name: "打草机", value: 51, unit: ' '},
            {name: "播种机", value: 21, unit: ' '},
            {name: "家用车辆", value: 32, unit: ' '}
          ]
        }

        this.data3 = {
          title: {
            text: "机械化统计",
            left: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '吨',
          x: ['干草', '饲料', '肥料'],
          y: [20, 18, 30]
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        // let val = {
        //   title: '乡镇人口统计',
        //   yName: '人',
        //   x: ['20岁以下', '20-30岁', '30-50岁', '50岁以上'],
        //   y: [233, 328, 658, 252]
        // }
        // this.$router.push({
        //   path: "/bigscreen/personState" + JSON.stringify(val)
        // })

        // let title = `xxx村人口统计`

        // this.$router.push({
        //   path: "/bigscreen/personState" + title
        // })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        // let val = {
        //   title: '乡镇机械化统计',
        //   yName: '辆',
        //   x: ['四轮车', '大型货车', '大型拖拉机', ' 播种机械', '打草机械', '家用车辆'],
        //   y: [340, 5, 5, 12, 16, 36]
        // }
        // this.$router.push({
        //   path: "/bigscreen/personState" + JSON.stringify(val)
        // })

        // let title = `xxx村机械化统计`

        // this.$router.push({
        //   path: "/bigscreen/mashineState" + title
        // })
      },

      // 获取牧户详情
      async getMuHuDetail(id) {
        let params = { id: id }
        let res = await getMuHuDetail(params)
        this.muHuDetailData = res.data
      },

      // 前往 牛详情
      showOxDetail(index) {
        let id = this.muHuDetailData.cattle_list[index].id
        console.log(id);
        this.$router.push({
          path: "/bigscreen/oxDetail" + id
        })
      }

    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .list_box {
    height: calc(100% - 60px) !important;
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>